.program-header {
    background-color: #f2f2f2;
    padding: 0 15px;
    font-size: 18px;
    border-bottom: 1px solid;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    font-weight: 600;
    justify-content: space-between;
}

.program-label {
    min-width: 150px;
    max-width: 150px;
    color: #000 !important;
}

.program-container {
    /* border: 1px solid;
    border-radius: 10px;
    background-color: #fff; */
}

.program-body {
    padding: 15px;
    /* background: #fff; */
}

.width-fit {
    width: fit-content;
}