.upload-modal .modal-content {
    background-color: #fff !important;
    color: #000;
}


.width-35{
width: 35%;
}

.width-65{
    width:65%
}

.user-form label{
    font-size: 14px;
    color: #000;
}