.grid-container {
    width: 90vw;
    margin: 0 auto;
    height: 90vh;
    display: flex;
    border: 2px solid rgba(0, 0, 0, .4);
    /* transition: 0.3s ease; */
}

.sidebar {
    display: flex;
    flex-direction: column;
    min-width: 15rem;
    height: 100vh;
    box-shadow: 5px 0px 10px 0px rgb(0 0 0 / 20%);
    z-index: 25;
    transition: 0.3s ease;
    position: relative;
}

.sidebar.collapsed {
    min-width: 4rem;
}

.menu-text {
    white-space: nowrap;
}

.sidebar.collapsed .menu-text,
.sidebar.collapsed .status-icon {
    animation-fill-mode: both;
    display: none;
}

.sidebar.collapsed:hover {
    box-shadow: 5px 0px 10px 0px rgb(0 0 0 / 20%);
    min-width: 15rem;
}

.sidebar.collapsed:hover .menu-text,
.sidebar.collapsed:hover .status-icon {
    display: block;
}

.main-content {
    transition: 1s ease;
}

.sidebar_small {
    width: 10%;
}


.brand {
    font-family: 'Chakra Petch', sans-serif;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    white-space: nowrap;
    cursor: pointer;
}

.menu-icon {
    font-size: 1.5rem;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    color: gray;
}

.menu-icon:hover {
    color: #000
}


.has-submenu {
    position: relative;

}

.s-menu-item ul {
    display: none !important;
    list-style: none;
    padding: 0;
}

.active+ul,
.collapsed+a+ul {
    display: block !important;
}


.sidebar-content {
    padding: 8px;
    overflow: auto;
}

.sidebar-menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

.s-menu-dropdown .dropdown-menu {
    position: relative !important;
    transform: none !important;
    border: none;
    padding: 0;
    /* padding-left: 10px; */
    background: transparent;
    min-width: 3rem;
}

.s-menu-item {
    position: relative;
}

.s-menu-item .link {
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
    margin-bottom: 5px;
    border-radius: .25rem;
}

.s-menu-item .link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    text-decoration: none;
    color: #212529;
    padding: 10px 15px;
    line-height: 12px;
    margin-bottom: 10px;
    font-size: 16px;
}

.s-menu-icon-title {
    display: flex;
    align-items: center;
    gap: 10px;
}

.s-menu-dropdown .dropdown-toggle {
    padding: 0;
}

.s-menu-dropdown.show .dropdown-toggle {
    background: #cae4ff;
    border-radius: 5px;
}

.s-menu-dropdown.show {
    background: #c9c9c963;
    border-radius: 5px;
}

.s-menu-dropdown.show i.fa.fa-angle-right {
    transform: rotate(90deg);
}

.s-menu-dropdown .dropdown-toggle::after {
    display: none;
}

.s-menu-item .link:hover {
    color: #FFF;
    background-color: #3498DB;
}

.s-menu-item .active {
    color: #FFF;
    background-color: #3498DB;
    font-weight: 600;
}

.success {
    color: #007E33;
}

.danger {
    color: #CC0000;
}

.header-dropdon .dropdown-toggle {
    border: 1px solid #ccc;
    padding: 6px 12px;
    padding-right: 24px;
    border-radius: .25rem;
    cursor: pointer;
    min-width: 150px;
}

.header-dropdon .dropdown-toggle::after {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

.header-dropdon .dropdown-item {
    cursor: pointer;
}