.custom-file {
    position: relative;
    height: 35px;
}

.custom-file-input {
    width: 100%;
    height: 100%;
    opacity: 0;
}

label.custom-file-label {
    position: absolute;
    border: 1px solid #ced4da;
    padding: .375rem .75rem;
    line-height: 1.5;
    left: 0;
    right: 0;
    color: #495057;
    background-color: #fff;
    border-radius: 0.25rem;
    box-shadow: none;
}

.custom-file-label::after {
    content: 'Browse';
    position: absolute;
    top: 0;
    right: -1px;
    bottom: 0;
    z-index: 3;
    display: block;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-right: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
    cursor: pointer;
}

select.form-control {
    padding-right: 1.75rem !important;
    /* background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat; */
    background: #fff url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PgogICAgICAgIDwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgCiAgICAgICAgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+ICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiBmaWxsPSJjdXJyZW50Q29sb3IiIGNsYXNzPSJiaSBiaS1jYXJldC1kb3duLWZpbGwiIHZpZXdCb3g9IjAgMCAxNiAxNiI+IDxwYXRoIGQ9Ik03LjI0NyAxMS4xNCAyLjQ1MSA1LjY1OEMxLjg4NSA1LjAxMyAyLjM0NSA0IDMuMjA0IDRoOS41OTJhMSAxIDAgMCAxIC43NTMgMS42NTlsLTQuNzk2IDUuNDhhMSAxIDAgMCAxLTEuNTA2IDB6Ii8+IDwvc3ZnPiAg") right 0.75rem center/8px 10px no-repeat;
    min-width: max-content;
}

.checkbox-group {
    align-items: center;
    flex-direction: row !important;
    gap: 10px;
}

.form-group {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-bottom: 20px;
}

.form-group:last-child {
    margin-bottom: 0;
}


.form-control {
    min-width: 50px;
}

.admin-modal .form-control:disabled,
.admin-modal .form-control[readonly] {
    background-color: #e9ecef;
}

.progress-input input {
    max-width: 50px;
    text-align: center;
}

.ck-editor__editable {
    min-height: 70px;
}

.image-upload {
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* border: 1px solid #ced4da; */
    border-radius: 5px;
}

.chips-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
}

.chips-group-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;

}

.chips {
    background-color: lightgray;
    gap: 20px;
    width: fit-content;
    border-radius: 20px;
    padding: 5px 15px;
    cursor: pointer;
}

.chip-clear {
    cursor: pointer;
    margin-left: 8px;
}

.chip-clear {
    opacity: .75;
}

.btn-disabled {
    cursor: not-allowed;
    opacity: .5;
}

.btn-disabled:focus,
.btn-disabled:active:focus {
    box-shadow: none
}


.edit-button-align .d-flex {
    justify-content: center;
}

.file-upload-spinier{
    float: right;
    margin-right: 70px;
}