.login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.login-page form {
    width: 100%;
    max-width: 360px;
    padding: 20px 25px;
    margin: 0 auto;
    border: 1px solid lightgray;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}