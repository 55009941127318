.add-mission-button {
    background: #f2f2f2;
    padding: 10px 0;
    text-align: center;
    border: 1px black dashed;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 5px;
}

.mission-box {
    margin: 10px 0px;
    border: 1px solid #797979 !important;
    border-radius: 5px;
}

.mission-header {
    /* background: #7f7f7f; */
    color: #fff;
    /* padding: 10px 15px; */
    display: flex;
    font-size: 18px;
    justify-content: space-between;
}

/* .accordion-button:not(.collapsed){
    background: #7f7f7f;
}

.accordion-button{
    background: #7f7f7f;
} */

.accordion-button {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.accordion-button.collapsed {
    border-radius: calc(0.25rem - 1px) !important;
}

.mission-body {
    padding: 15px;
}

.mission-label {
    min-width: 150px;
    max-width: 150px;
    color: #000 !important;
}

.mission-width-number {
    width: 18vw;
}

.mission-width-name {
    width: 30%;
}

.mission-full-width {
    width: -webkit-fill-available;
}


.activity-textarea {
    margin-bottom: 16px;
}


.copy-mission-container {
    display: flex;
    align-items: center;
    margin-top: 15px;
}


.accordion-button {
    background-color: #f2f2f2;
    padding: 0.4rem 1.25rem !important;
}

.accordion-button:not(.collapsed) {
    color: #000;
    background-color: #f2f2f2;

}