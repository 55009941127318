.schedule-label{
    display: flex;
    align-items: center;
}

.ps-5px{
    padding-left:5px;
}

.pe-5px{
 padding-right: 5px;   
}