@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

:root {
    --color-primary: #00a8ff;
    --color-secondary: #00FFCA;
    --bs-light-rgb: 248, 249, 250;
}

.admin-layout {
    font-size: 14px;
    display: flex;
    background: #fff;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #000 !important;
}

.admin-header,
.brand {
    /* width: 100%; */
    min-height: 4rem;
    padding: 10px 15px;
    background: #f8f9fa !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.admin-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF !important;
    position: sticky;
    top: 0;
    z-index: 20;
}

.header-left {
    display: flex;
    gap: 15px;
    align-items: center;
}

.main-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background-color: #f4f6f9;
}

.admin-content {
    padding: 15px 20px;
    flex: 1;
}

.admin-profile {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    /* color: #FFF; */
    text-transform: capitalize;
}

.user-info,
.info {
    display: flex;
    flex-direction: column;
}

.user-info .user-role,
.info .title {
    font-size: 14px;
    color: #000;
}

.user-info .user-role,
.info .sub-heading {
    font-size: 12px;
    color: #7F7F7F;
}

.avatar {
    font-size: 16px;
    font-weight: 600;
    width: 40px;
    height: 40px;
    background: #6c757d;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23) !important;
}

.avatar img {
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23) !important;
}

.upload-modal .modal-content,
.admin-modal .modal-content {
    background-color: #FFF !important;
    color: #000;
}

.modal-header {
    padding: 10px 16px !important;
}


.page-header {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-transform: capitalize;
}

.page-header h1 {
    font-size: 1.7rem;
    margin: 0;
}

.alert {
    padding: 10px 15px;
    margin: 0;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 0;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    cursor: pointer;
}

.close:hover {
    opacity: .75;
}


.pointer {
    cursor: pointer;
}

.no-border {
    border: none;
    border-color: transparent;
}

.page-actions {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    gap: 10px;
}

.btn {
    min-width: unset !important;
}

.btn-rounded {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px !important;
    height: 30px;
    padding: 0;
    border-radius: 50%;
}

.modal-title {
    font-size: 1rem;
}

.gap-1 {
    gap: 10px;
}

.modal-backdrop.show:nth-child(2) {
    /* display: none; */
    opacity: 0.5 !important;
}

/* .s-menu-item:hover ul {
    display: block !important;
} */

.error-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.error-box {
    text-align: center;
    padding: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: #fbeeed;
    border-color: #f7d8dd;
    color: #d9534f;
    min-width: 50%;
}

.btn {
    display: flex;
    align-items: center;
}

.btn-text {
    text-decoration: none !important;
    box-shadow: none !important;
}

.btn-text:hover {
    text-decoration: none !important;
    box-shadow: none;
}

i.fa {
    min-width: 15px;
    text-align: center;
    min-height: 15px;
}

.s-menu-caret {
    position: absolute;
    top: 11px;
    right: 10px;
    font-weight: 600;
    transition: all .2s ease;
}

.s-menu-caret.collapsed,
.active+.s-menu-caret {
    transform: rotate(90deg);
}

li.menu-divider {
    border-bottom: 1px solid lightgray;
    margin-bottom: 10px;
}

.accordion-button::after {
    margin-left: 0 !important;
}

a.link.disabled {
    filter: grayscale(1);
    cursor: not-allowed;
    color: gray;
}

/* ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-button {

    height: 5px;
    width: 5px;
    border-radius: 50%;
}

::-webkit-scrollbar-thumb {
    /* background: #00AEFF; 
    border-radius: 5px;
} */


.loader-background {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
}

.action-buttons {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px !important;
}

.section {
    padding: 15px;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin: 10px 0;
}

.text-right {
    text-align: right !important;
}

.question-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    gap: 8px
}

.question-header .btn {
    padding: 0 3px;
}

.user-name-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 5px;
}

.user-name {
    cursor: pointer;
    text-transform: capitalize;
}

@media (min-width: 576px) {
    .modal-dialog.modal-md {
        max-width: 650px !important;

    }
}

div[role="dialog"][aria-modal="true"]:nth-last-child(1) {
    z-index: 1125;
}

.modal-backdrop.show:nth-last-child(2) {
    z-index: 1100;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(3) {
    z-index: 1075;
}

.modal-backdrop.show:nth-last-child(4) {
    z-index: 1050;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(5) {
    z-index: 1025;
}

.modal-backdrop.show:nth-last-child(6) {
    z-index: 1000;
}

.required-star {
    color: red;
}

.w-10 {
    width: 10px;
}

.fa-1 {
    font-size: 1rem;
}

.fa-2 {
    font-size: 1.5rem;
}

.fa-3 {
    font-size: 2rem;
}


.fa-5 {
    font-size: 5rem;
}

.text-left {
    text-align: left !important;
}

.confirm-btn {
    min-width: 100px !important;
}

.admin-content-lock {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
}

.admin-content-lock-text {
    min-width: 50%;
    height: 250px;
    border: 1px solid lightgray;
    border-radius: 8px;
    /* box-shadow: 5px 5px 15px rgb(0 0 0 / 20%); */
    padding: 25px;
}

.admin-content-lock .text {
    letter-spacing: 1px;
    line-height: 2rem;
    max-width: 500px;
    margin: auto;
    color: gray;
}

.stage-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 10px;   */
    width: 36px;
    height: 36px;
}


.flex-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.icon-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
    font-size: 25px;
    border: 1px solid gray;
    border-radius: 4px;
    cursor: pointer;
}

.icon-item:hover {
    border: 1px solid #00AEFF;
    color: #00AEFF;
}

.icon-selector {
    font-size: 18px;
    cursor: pointer;

    width: 100%;
    height: 100%;
    justify-content: center;
}

.btn:focus {
    box-shadow: none !important;
}

.card-header.title {
    padding: 0.3rem 1rem;
    font-weight: 600;
    background-color: rgba(85, 85, 85, 1);
    color: #FFFFFF;
}

.mission-progress {
    margin-bottom: 20px;
}

.cdx-progress {
    height: 22px !important;
    background-color: rgba(102, 169, 197, 1) !important;
    flex: 1 auto;
}


.cdx-success .progress-bar {
    background-color: rgba(3, 161, 19, 1);
}


.cdx-progress .progress-bar {
    text-align: right;
    font-weight: 600;
    padding-right: 10px;
}

.progress-bar-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.max-count {
    position: absolute;
    right: 25px;
    font-weight: 600;
}

.data-color {
    background-color: #00AEFF;
    width: 18px;
    height: 18px;
}

.cdx-available {
    background-color: rgba(102, 169, 197, 1);
}

.cdx-success {
    background-color: rgba(3, 161, 19, 1);
}

.data-variable {
    display: flex;
    align-items: center;
    gap: 20px;
}

.data-variable-item {
    display: flex;
    align-items: center;
    gap: 5px;
}

.dashboard-graph .col-md-6 {
    margin-bottom: 1rem;


}

.float-right {
    float: right;
}

.no-record {
    text-align: center;
    padding: 25px;
    font-weight: bold;
    color: gray;
    text-transform: capitalize;
    font-size: 1rem;
}

.preview-wrapper {

    border-radius: 5px;
    min-height: 100px;
}

.thin-border.spinner-border-sm {
    border-width: 2px !important;
}

.code-editor {
    background: rgb(40, 44, 52);
    color: rgb(255, 255, 255);
    /* border-radius: 10px; */
    overflow: auto;
    tab-size: 1.5em;
    min-height: 50vh;
    max-height: calc(100vh - 342px);
}

.code-editor textarea:focus-visible {
    outline: none;
}

.border-left {
    border-left: 1px solid lightgray;
}


.divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 10px 0;
}

.flex-auto {
    flex: 1 1 auto;
}

.wrap-text {
    white-space: pre-wrap !important;
}