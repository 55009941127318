.bg-success,
.bg-danger,
.bg-dark,
.bg-info {
    color: #FFF;
}


.toast-container-wrapper {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
}

.toast-message {
    animation: fadeInRight 0.3s ease-in-out;
    animation-fill-mode: forwards;
}

.toast-message:hover {
    box-shadow: 0 0 12px #FFF;
    opacity: 1;
    transition: 0.3s ease;
}

@keyframes fadeInRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}