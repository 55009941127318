.table td,
.table th {
    vertical-align: middle;
}

.table-data {
    overflow-x: auto;
}

.hidden {
    display: none;
}

.table-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: gray;
    padding: 15px 0;
    gap: 10px;
}

.table-filters {
    padding: 10px 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.filter-range {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.data-table-action {
    display: flex;
    justify-content: space-evenly;
}

.locked button:not(.action) {
    pointer-events: none;
    opacity: .70;
}

.table tr th {
    text-transform: capitalize;
}

.table tr th,
.table tr td {
    white-space: nowrap;
}

.btn-xm {
    padding: 0.1rem 0.3rem;
    font-size: .875rem;
    border-radius: 0.2rem;
}

.left-side,
.right-side {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}

.left-side {
    justify-content: flex-start;
}


@media screen and (max-width: 775px) {

    .left-side,
    .right-side {
        justify-content: space-between;
    }

    .left-side {
        width: 100% !important;
    }

    .pagination-wrapper {
        width: 100% !important;
        display: flex;
        justify-content: center;
    }
}